import React from "react";

import Layout from "components/Global/Layout";

const PartnersPage = () => {
	return (
		<Layout
			pageName="partners"
			amazon="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas"
		>
			<div className="partners">
				<h2>Partners</h2>
				<p className="partners__description">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis elit
					felis, vestibulum semper lobortis nec, accumsan fringilla augue. Proin
					egestas magna gravida eros mattis, non ultrices velit scelerisque.
				</p>
				<ul>
					<li>
						<img
							src="https://www.telegraph.co.uk/content/dam/Travel/2016/October/caravaggio-supper-at-emmaus-GETTY.jpg?imwidth=680"
							alt="Partner Name"
						></img>
						<p>
							Fusce tincidunt tellus eget rhoncus blandit. Nulla nec malesuada
							turpis. Etiam eget tortor quis felis vestibulum pellentesque.
							Fusce sit amet viverra sapien. Duis a pharetra leo. Sed pretium
							eget risus ac vestibulum.
						</p>
					</li>

					<li>
						<img
							src="https://www.telegraph.co.uk/content/dam/Travel/2016/October/caravaggio-supper-at-emmaus-GETTY.jpg?imwidth=680"
							alt="Partner Name"
						></img>
						<p>
							Fusce tincidunt tellus eget rhoncus blandit. Nulla nec malesuada
							turpis. Etiam eget tortor quis felis vestibulum pellentesque.
							Fusce sit amet viverra sapien. Duis a pharetra leo. Sed pretium
							eget risus ac vestibulum.
						</p>
					</li>
				</ul>
			</div>
		</Layout>
	);
};

export default PartnersPage;
